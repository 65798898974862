import React from 'react'
import { VictoryBar, VictoryChart, VictoryAxis } from 'victory'

function BarGraph({ data, children }) {
  const maxY = Math.max(...data.map(d => d.y))
  return <VictoryChart padding={0} height={100} width={134} minDomain={{ x: 0.2, y: -1 * (maxY / 100 * 8)  }} maxDomain={{ x: data.length + 1 - 0.2, y: maxY * 1.5 }}>
    <VictoryAxis dependentAxis tickFormat={() => ""} />
    <VictoryAxis tickFormat={() => ""} offsetY={0} />
    <VictoryBar 
      animate={{
        duration: 2000
      }}    
      style={{
        data: {
          fill: ({ datum }) => datum.fill,
        }
      }}
      barWidth="16"
      data={data}
    />
    {children}
  </VictoryChart>
}

export default BarGraph
