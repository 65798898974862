import React, { useEffect, useState } from 'react'
import { ModalBackdrop } from './Modal.style'
import Card from '../Card'
import PropTypes from 'prop-types'
import { useIsMount } from '../../../hooks'

function Modal({ children, open, className, noCard, zIndex, redrawOnOpen }) {
  const [animating, setAnimating] = useState(false)
  const [isOpen, setIsOpen] = useState(open)
  const isMount = useIsMount()

  useEffect(() => {
    if (!isMount) {
      setAnimating(true)
      setTimeout(() => {
        setAnimating(false)
        setIsOpen(open)
      }, 1000)
    }
  }, [open])

  return <ModalBackdrop zIndex={zIndex} open={isOpen} animating={animating} className={className}>
    {noCard ? children : (
    (redrawOnOpen ? ((animating || isOpen) && <Card>
      {children}
    </Card>) : <Card>{children}</Card>))}
  </ModalBackdrop>
}

Modal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool
}

export default Modal