import React from 'react'
import { BackActionHeaderContainer, BackArrowImage } from './BackActionHeader.style'
import Text from '../../generic/Text'
import { leftArrow } from '../../../images/header'

function BackActionHeader({ title, onClick }) {
  return <BackActionHeaderContainer onClick={onClick}>
    <BackArrowImage src={leftArrow} alt="<" />
    <Text size={14}>Back{title && " to"} {title}</Text>
  </BackActionHeaderContainer>
}

export default BackActionHeader
