import React, { useState } from 'react'
import Step, { StepState } from './Step'
import { StepperContainer, StepperContainerMobile } from './Stepper.style'

interface IStepData {
  text: string
}

interface IStepperProps {
  currentStep: number
  stepData: IStepData[]
  stepperController: StepperController
  isMobile: boolean
}

class StepperController {
  stepperStepState: [number, React.Dispatch<React.SetStateAction<number>>]
  stepData: IStepData[]

  public currentStep = 0
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>

  public setupController(
    stepperStepState: [number, React.Dispatch<React.SetStateAction<number>>],
    stepData: IStepData[]
  ) {
    this.stepperStepState = stepperStepState
    this.stepData = stepData
    this.currentStep = stepperStepState[0]
    this.setCurrentStep = stepperStepState[1]
  }

  public previousStep(): number | void {
    if (this.stepData == null) return
    const [currentStep, setCurrentStep] = this.stepperStepState
    const newStep = Math.max(0, currentStep - 1)
    setCurrentStep(newStep)
    return newStep
  }
  public nextStep(): number | void {
    if (this.stepData == null) return
    const len = this.stepData.length
    const [currentStep, setCurrentStep] = this.stepperStepState
    const newStep = Math.min(len - 1, currentStep + 1)
    setCurrentStep(newStep)
    return newStep
  }
}

function Stepper({ currentStep: givenCurrentStep, stepData, stepperController, isMobile }: IStepperProps) {
  const stepStateTp = useState<number>(1)
  stepperController && stepperController.setupController(stepStateTp, stepData)
  //const [currentStep, setCurrentStep] = stepStateTp;
  const currentStep = stepperController ? stepperController.currentStep : givenCurrentStep
  const Container = isMobile ? StepperContainerMobile : StepperContainer

  return (
    <>
      <Container>
        {stepData.map((stepData: IStepData, index) => {
          let stepState = StepState.todo
          switch (true) {
            case currentStep > index:
              stepState = StepState.completed
              break
            case currentStep == index:
              stepState = StepState.active
              break
          }
          return <Step key={index} number={index + 1} text={stepData.text} state={stepState} isMobile={isMobile} />
        })}
      </Container>
    </>
  )
}

export { StepperController }

export default Stepper
