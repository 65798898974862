import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/core'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const ModalBackdrop = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => `#${props.theme.colors.primary.replace("#","")}68`};
  z-index: ${props => props.zIndex ? props.zIndex : 1000};
  
  ${props => props.animating && (props.open
    ? css`animation: ${fadeOut} 1s ease`
    : css`animation: ${fadeIn} 1s ease`)}

  ${props => !props.animating && (!props.open 
    ? css`display: none; opacity: 0`
    : css`opacity: 1`)}  

`

export {
  ModalBackdrop
}