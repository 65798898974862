import React from "react"

function Video({ src, title, ...props }) {
  return <iframe
      src={src}
      title={title}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      width="100%"
      height="100%"
      allowFullScreen
    />
}

export default Video