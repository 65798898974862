import styled from '@emotion/styled'

const AmountSliderContainer = styled.div`
  padding-top: 14px;
  padding-left: 28px;
  padding-right: 28px;

  .MuiSlider-root {
    color: ${props => props.theme.colors.primary};
  }
  
  .PrivateValueLabel-circle {
    width: 50px;
    height: 50px;
  }
`

const CircleButton = styled.div`
  background-color: ${props => props.theme.colors.primary};
  width: 74px;
  height: 74px;
  border-radius: 37px;
`

export {
  AmountSliderContainer,
  CircleButton
}