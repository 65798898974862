
import Numeral from 'numeral'
import 'numeral/locales/en-za.js'
import moment from 'moment'

// load a locale
Numeral.register('locale', 'za', {
  delimiters: {
    thousands: ' ',
    decimal: '.'
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  currency: {
    symbol: 'R'
  }
});

// switch between locales
Numeral.locale('za')


function printCurrency(amount, decimals = true) {
  return decimals ? Numeral(amount).format('$ 0,0[.]00') : Numeral(amount).format('$ 0,0')
}

const toDecimalPlaces = (value, decimals) => Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)

function printDate(date) {
  if (date && parseInt(date).toString() === date) {
    return moment(parseFloat(date)).format("YYYY-MM-DD")
  }

  return date ? moment(date).format('YYYY-MM-DD') : ""
}

function prettyText(text) {
  return text.split(' ').map(i => i[0].toUpperCase() + i.slice(1).toLowerCase()).join(' ')
}

export {
  printCurrency,
  toDecimalPlaces,
  printDate,
  prettyText
}