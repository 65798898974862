import React from 'react'
import { VictoryStack, VictoryBar, VictoryChart, VictoryAxis } from 'victory'

function StackedBarGraph({ data, children }) {
  const totalY = {}
  data.forEach(series => {
    series.forEach(d => {
      if (!totalY[d.x]) {
        totalY[d.x] = 0
      }
      totalY[d.x] += parseFloat(d.y)
    })
  })
  const maxY = Math.max(...Object.keys(totalY).map(d => totalY[d]))
  
  return (
    <VictoryChart padding={0} height={100} width={134} minDomain={{ x: 0.2, y: -1 * (maxY / 100 * 8)  }} maxDomain={{ x: Object.keys(totalY).length + 1 - 0.2, y: maxY * 1.5 }}>
    <VictoryAxis dependentAxis tickFormat={() => ""} />
    <VictoryAxis tickFormat={() => ""} offsetY={0} />
    <VictoryStack
      animate={{
        duration: 2000
      }}
    >
      {data.map((d, i) => <VictoryBar
        key={i}
        style={{
          data: {
            fill: ({ datum }) => datum.fill,
          }
        }}
        barWidth="16"
        data={d}
      />)}
    </VictoryStack>
    {children}
    </VictoryChart>
  )
}

export default StackedBarGraph
