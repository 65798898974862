import React from 'react'
import Text from '../Text'
import { StepContainer, StepContainerMobile, StepHeaderText, StepHeaderTextMobile } from './Stepper.style'
import { useTheme } from 'emotion-theming'
import { IMeerkatWebTheme } from '../../../theme.style'

enum StepState {
  completed,
  active,
  todo,
}

function Step({
  text,
  number,
  state,
  isMobile,
}: {
  text: string
  number: number
  state: StepState
  isMobile: boolean
}) {
  const theme: IMeerkatWebTheme = useTheme()
  let backgroundColor = theme.colors.white
  let headerTextColor = theme.colors.primary
  let mainTextColor = '#404457' //TODO: missing color
  switch (state) {
    case StepState.completed:
      backgroundColor = theme.colors.lightGreen
      headerTextColor = theme.colors.primary
      mainTextColor = theme.colors.primary
      break
    case StepState.active:
      backgroundColor = theme.colors.primary //TODO: missing color
      headerTextColor = isMobile ? theme.colors.white : theme.colors.secondary
      mainTextColor = theme.colors.white
      break
    case StepState.todo:
      // -> use defaults
      break
  }
  const containerStyle = {
    backgroundColor,
  }

  if (isMobile) {
    return (
      <div style={{ width: '100%' }}>
        <StepContainerMobile style={containerStyle}>
          <StepHeaderTextMobile style={{ color: headerTextColor }} size={15.3} bold>
            {number}
          </StepHeaderTextMobile>
        </StepContainerMobile>
      </div>
    )
  }

  return (
    <>
      <StepContainer style={containerStyle}>
        <StepHeaderText style={{ color: headerTextColor }} size={15.3} bold>{`STEP ${number}`}</StepHeaderText>
        <div style={{ height: '8px' }} />
        <Text style={{ color: mainTextColor }} size={20}>
          {text}
        </Text>
      </StepContainer>
    </>
  )
}

export { StepState }

export default Step
