import React, { useState, useEffect } from 'react'
import { VictoryPie } from 'victory'
import { useTheme } from 'emotion-theming'
// import {} from './SpiralGraph.style'

// value, min, max, color

function BucketLabel({ x, y, label }) {
  return <g>
    <rect x={x} y={y} width={87} height={13} fill="#F1F1F1CC"></rect>
    <text x={x + 43} y={y + 10} textAnchor="middle" style={{ fontWeight: 400, fontSize: "10px", fill: "#333333" /* Grey 1 */ }}>{label}</text>
  </g>
}

function SpiralGraph({ width, height, series, centerLabel }) {
  const theme = useTheme()
  const [shownPercentage, setShownPercentage] = useState(0)
  
  useEffect(() => {
    setTimeout(() => {
      setShownPercentage(100)
    }, 100)
  }, [])

  const half = ((Math.min(width || 0, height || 0) || 280) / 2)

  return <svg width={width || "100%"} height={height || "auto"} viewBox={`0 0 ${width || 280} ${height || 280}`} preserveAspectRatio="xMidYMid meet"> 
    {series.map((_, index) => <circle key={index} cx={(width || 280) / 2} cy={(height || 280) / 2} r={half - 5 - (index * 20)} stroke={theme.colors.lightGreen} strokeWidth="10" fill="none" />)}
    {series.map(({ min, max, color, value }, index) => {
      const percentage = Math.min(100, ((value - (min || 0)) / ((max || value) - (min || 0)) * 100)) * (shownPercentage / 100)


      return <VictoryPie
        key={index}
        standalone={false}
        animate={{
          duration: 2000
        }}
        cornerRadius={5}
        data={[percentage, 100 - percentage]}
        width={Math.min(width || 0, height || 0) || 280}
        height={Math.min(width || 0, height || 0) || 280}
        colorScale={[theme.colors[color || "primary"], theme.colors.lightGreen]}
        radius={half - (index * 20)}
        innerRadius={half - 10 - (index * 20)}
        labels={() => ""}
      />
    })}
    {series.map(({ label }, index) => label && <BucketLabel x={50} y={index * 20} label={label} />)}
    {centerLabel && <text width={width / 2} height="auto" x={(width || 280) / 2} y={(height || 280) / 2} textAnchor="middle" style={{ fontWeight: 400, fontSize: "13px", fill: "#333333" /* Grey 1 */ }}>{centerLabel}</text>}
  </svg>
}

export default SpiralGraph
