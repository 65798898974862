import styled from '@emotion/styled'

const BackActionHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: flex-start;
  align-items: center;
`

const BackArrowImage = styled.img`
  margin: 0;
  margin-right: 17px;
  height: 12px;
  margin-bottom: 1px;
`

export {
  BackActionHeaderContainer,
  BackArrowImage
}
