import styled from '@emotion/styled'

const CardContainer = styled.div`
  box-shadow: 0px 2px 4px rgba(0,0,0,0.2);
  background-color: #FFF;
  margin: 7px;
`

export {
  CardContainer
}