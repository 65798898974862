import styled from '@emotion/styled'

const PdfViewerContainer = styled.div`
  width: calc(100% - 10%);
  max-width: 1110px;
  margin-bottom: 4em;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;

  .react-pdf__Document {
    border-radius: 8px;
    max-width: 100%;
    position: relative;
    background-color: white;
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  }

  .react-pdf__Page {
    max-width: 400px;
    width: 80vw;
  }
  .react-pdf__Page.rendering {
    position: absolute !important;
    opacity: 0;
    z-index: -1;
  }
  .react-pdf__Page.departuring {
    opacity: 0.5;
    transition: opacity ease-in-out 200ms;
  }

  .react-pdf__Page__svg {
    width: auto !important;
    height: auto !important;
    border-radius: 8px;
  }

  .react-pdf__Page__svg svg {
    border-radius: 8px;
    max-width: 400px;
    width: 80vw;
    height: auto;
    display: block;
    background-color: white;
  }
`

export {
  PdfViewerContainer
}
