import React from 'react'
import styled from '@emotion/styled'
import  { keyframes } from '@emotion/core'
import Card from '../Card'

const CardText = styled.span`
  margin-bottom: 21px;
  margin-top: 28px;
  font-size: 20px;
`

const coolBoxKeyframes = keyframes`
  0% {
    transform: scale(0.5)
  }
  100% {
    transform: scale(1.0)
  }
`

const CardAnimation = styled.div`
  animation-name: ${coolBoxKeyframes};
  animation-duration: 0.1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
`

const CardContainer = styled.div`
  margin-top: 14px;
  margin-bottom: 14px;
`

const AnimatedCard = (props) => <CardAnimation><CardContainer {...props} /></CardAnimation>

const CardContent = styled.div`
  padding-top: ${props => props.empty ? "0px" : "7px"};
`

const CardNextButtons = styled.div`
  margin-top: 16px;
`

export {
  CardText,
  AnimatedCard,
  CardContent,
  CardNextButtons
}