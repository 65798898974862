import React, { Fragment, useState, useEffect, useRef } from 'react'
import Card from '../../generic/Card'
import Button from '../../generic/Button'
import Text from '../../generic/Text'
import { Row, Col } from '../../generic/grid'
import { FormField, FieldValues, FieldValue, SubmitFormButton } from 'react-form-helper'
import { CardText, AnimatedCard, CardContent, CardNextButtons } from './ChatCard.style'
import ActivityIndicator from '../ActivityIndicator'
import firebase from 'gatsby-plugin-firebase'

// TODO: Make a util in react form helper
import { touch } from 'redux-form'
import { connect } from 'react-redux'
import BackActionHeader from '../../shared/BackActionHeader'
import Mandatory from '../form/Mandatory'


const renderChildren = ({ children }) => children

// TODO: Add a field layout
function ChatCard({ submitBeforeStep, submit,handleNext, nextAfterDelay, text, fieldNames, requiredFields, labels, noNext, children, focus, nextTitle, submitting, nextOnValue, nextOnBlur, isActive = true, nextButtons, nextButtonsInColumn, formSubmitDone, setFormSubmitDone, cardContentStyle, setTouched, renderRequestContainer, renderResponseContainer, goBack, showBack, fieldEvents }) {
  let shownCard = useRef(null)
  const [formSubmit, setFormSubmit] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [nextPressed, setNextPressed] = useState(false)

  // TODO: Clear the timeouts if not necessary
  useEffect(() => {
   setTimeout(() =>  {
      shownCard && shownCard.current && shownCard.current.scrollIntoView({
        behaviour: "smooth",
        block: "center",
        inline: "center"
      });
      if (nextAfterDelay || nextAfterDelay === 0) {
        setTimeout(() => {
          handleNext(null)
        }, nextAfterDelay)
      }
    }, 100)
  }, [])

  let inputProps
  const [autoNext, setAutoNext] = useState(false)

  if (nextOnValue) {
    inputProps= {
      onChange: (e, v) => { setAutoNext(true) }
    }
  }

  if (nextOnBlur) {
    inputProps= {
      onChange: (e, v) => { setAutoNext(true) }
    }
  }

  if (submitBeforeStep && !formSubmitted) {
    submit();
    setFormSubmitDone(false)
    setFormSubmit(true)
    setFormSubmitted(true)
    return <div />
  }

  if (formSubmit) {
    if (!formSubmitDone) {
      return <ActivityIndicator large="true"/>
    } else {
      setFormSubmit(false)
      return <div />
    }
  } 

  const gotoNext = (values, nextSteps) => {
    setNextPressed(true)
    handleNext(values, nextSteps)
  }

  const RequestContainer = renderRequestContainer || renderChildren
  const ResponseContainer = renderResponseContainer || renderChildren

  return (
    <div ref={shownCard} style={{ width: "100%", paddingLeft: "8px" }}>
      <AnimatedCard>
        <RequestContainer text={text}>
        <Text bold>{text}</Text>
        </RequestContainer>
        <ResponseContainer isActive={isActive}>
        {showBack && <BackActionHeader onClick={goBack} />}
      <CardContent style={cardContentStyle} empty={!fieldNames || fieldNames.length === 0}>
        {fieldNames && fieldNames.map((fieldName, index) =>
            labels ?
              (<div style={{ paddingRight: "7px", paddingBottom: "7px" }}><FormField
              mode={isActive ? "edit" : "display"}
              key={fieldName}
              name={fieldName}
              inputProps={{ ...inputProps, required: requiredFields && requiredFields.includes(fieldName), onBlur: fieldEvents && fieldEvents[index] ? (e, v) => { if (v) { firebase.analytics().logEvent(fieldEvents[index]); } } : null }}
              autoFocus={focus && fieldNames[0] === fieldName}
              /></div> )
              :
              (<div style={{ paddingRight: "7px",  paddingBottom: "7px" }}><FormField
                mode={isActive ? "edit" : "display"}
                noLabel
                key={fieldName}
                name={fieldName}
                inputProps={{ ...inputProps, required: requiredFields && requiredFields.includes(fieldName), onBlur: fieldEvents && fieldEvents[index] ? (e, v) => { if (v) { firebase.analytics().logEvent(fieldEvents[index]); } } : null  }}
                autoFocus={focus && fieldNames[0] === fieldName}
                /></div> )
        )}
      {requiredFields && <Mandatory />}
      {children}
      </CardContent>
      <CardNextButtons>
      {fieldNames ? (
        <FieldValues names={fieldNames} renderValues={({ values, errors }) => {
          const hasErrors = errors && Object.keys(errors).reduce((total, field) => errors[field] ? total + 1 : total, 0) > 0
          if (autoNext) {
            if (!hasErrors) {
              gotoNext(values)
            }
            setAutoNext(false)
          }
          return (
            <Fragment>
              {!noNext && !nextButtons && <Col centerHorizontal><Button action="true" submitting={submitting || nextPressed} onDisabledClick={!nextPressed ? () => { setTouched(fieldNames) } : null} disabled={hasErrors || nextPressed} onClick={() => gotoNext(values) }>{nextTitle || "Next"}</Button></Col>}
              {!noNext && nextButtons && !nextButtonsInColumn && <Row>{nextButtons.map((b, i) => <Col key={i} centerHorizontal><Button action="true" disabled={hasErrors || nextPressed} onClick={() => { if (b.onClick) { b.onClick() } if (!b.noNextStep) { gotoNext(values, b.nextSteps) }}} {...b.buttonProps || {}}>{b.label}</Button></Col>)}</Row>}
              {!noNext && nextButtons && nextButtonsInColumn && <Col centerHorizontal>{nextButtons.map((b, i) => <Col key={i} centerHorizontal style={{ marginBottom: "14px" }}><Button action="true" disabled={hasErrors || nextPressed} onClick={() => { if (b.onClick) { b.onClick() } if (!b.noNextStep) { gotoNext(values, b.nextSteps) }}} {...b.buttonProps || {}}>{b.label}</Button></Col>)}</Col>}
            </Fragment>
          )
        }} />
      ) : (
        <Fragment>
          {!noNext && !nextButtons && <Col centerHorizontal><Button action="true" submitting={nextPressed}  disabled={nextPressed} onClick={() => gotoNext(null)} >{nextTitle || "Next"}</Button></Col>}
          {!noNext && nextButtons && !nextButtonsInColumn && <Row>{nextButtons.map((b, i) => <Col key={i} centerHorizontal><Button action="true"  submitting={nextPressed} disabled={nextPressed} onClick={() => { if (b.onClick) { b.onClick() } if (!b.noNextStep) { gotoNext(null, b.nextSteps) }}} {...b.buttonProps || {}}>{b.label}</Button></Col>)}</Row>}
          {!noNext && nextButtons && nextButtonsInColumn && <Col centerHorizontal>{nextButtons.map((b, i) => <Col key={i} centerHorizontal style={{ marginBottom: "14px" }}><Button action="true"  submitting={nextPressed} disabled={nextPressed} onClick={() => { if (b.onClick) { b.onClick() } if (!b.noNextStep) { gotoNext(null, b.nextSteps) }}} {...b.buttonProps || {}}>{b.label}</Button></Col>)}</Col>}
        </Fragment>
      )}
      </CardNextButtons>
      </ResponseContainer>
      </AnimatedCard>
    </div>
  )
}

const mapDispatchToProps = (dispatch, { formName }) => ({
  setTouched: fieldNames => dispatch(touch(formName, ...fieldNames))
})

export default connect(() => ({}), mapDispatchToProps)(ChatCard)
