import React from 'react'
import Slider from '@material-ui/core/Slider'
import { AmountSliderContainer } from './AmountSlider.style'
import Text from '../Text'
import TextInput from '../TextInput'
import { printCurrency } from '../../../lib/utils'

function AmountSlider({ maxValue, minValue, step, value, onChange, marks,  }) {
  return <AmountSliderContainer>
    <center><Text large bold>{printCurrency(value)}</Text></center>
    <Slider 
      step={step}  
      aria-labelledby="discrete-slider-always"
      valueLabelDisplay="auto"
      min={minValue}
      max={maxValue}
      value={value}
      onChange={onChange}
      marks={[{ value: minValue, label: printCurrency(minValue, 0) }, { value: maxValue, label: printCurrency(maxValue, 0) }]}
    />
  </AmountSliderContainer>
}

export default AmountSlider
