import { useState, useEffect } from "react";
import { gaTrack } from "../lib/tracking";

const useExperiment = (experimentId) => {
  const [variant, setVariant] = useState();
  useEffect(() => {
    console.log("R4=", window.R4)
    if (window.R4 && window.R4.ready && window.R4.abVariants) {
      setVariant(window.R4.abVariants && window.R4.abVariants[experimentId])
    } else {
      (async () => {
        if (window.dataLayer) {
          await window.dataLayer.push({ event: 'optimize.activate' })
        }
        gaTrack('event', 'optimize.callback', {
          name: experimentId,
          callback: (v) => setVariant(v),
        })
      })();
    }
  })
  return variant;
}

export default useExperiment