import React, { useState } from 'react'
import ActivityIndicator from "../ActivityIndicator";

export default function gotoFlowComponent(FlowComponent, generateProps) {
  const component = (componentProps) => {
    const [flowOpen, setFlowOpen] = useState(true)

    return <>
      <ActivityIndicator />
      <FlowComponent open={flowOpen} setOpen={value => { setFlowOpen(value); componentProps.gotoNext() }} {...(generateProps ? generateProps(componentProps) : {})} />
    </>
  }

  return component;
}