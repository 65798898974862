import { PdfViewerContainer } from './PdfViewer.style'
import React, { useState } from 'react';

import { pdfjs, Document, Page } from 'react-pdf';

function PdfViewer({ file }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return <PdfViewerContainer>
    <Document
      file={file}
      onLoadSuccess={onDocumentLoadSuccess}
      renderMode="svg"

    >
      <Page pageNumber={pageNumber} />
    </Document>
  </PdfViewerContainer>
}

export default PdfViewer
