import React, { Children, useState } from 'react'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import SwiperItems from '../SwiperItems'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import './swiper.css';

function MySwiper({ children, showCount = 3, noArrows, noProgress, autoPlay, delay }) {
  const [swiper, updateSwiper]  = useState(null);

  const data = useStaticQuery(graphql`{
    image1: file(relativePath: { eq: "dsc_0083.png" }) {
      childImageSharp {
        fixed(height: 174, width: 174) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    leftArrowDisabled: file(relativePath: { eq: "LeftArrowDisabled.png" }) {
      childImageSharp {
        fixed(height: 58, width: 58) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    rightArrow: file(relativePath: { eq: "RightArrow.png" }) {
      childImageSharp {
        fixed(height: 58, width: 58) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }`)

  console.log(swiper && swiper.activeIndex)

  return (
    <Swiper 
      getSwiper={updateSwiper}
      slidesPerView={1}
      slidesPerGroup={1}
      spaceBetween={10}
      shouldSwiperUpdate
      rebuildOnUpdate
      observer
      observeParent
      navigation={!noArrows && {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }}
      pagination={!noProgress && {
        el: '.swiper-pagination',
        type: 'bullets',
      }}
      autoplay={
        autoPlay && {
          delay: delay || 2500
        }
      }
      breakpoints={{
        1600: {
          slidesPerView: Math.min(showCount, 3),
          slidesPerGroup: Math.min(showCount, 3)
        },
        1040: {
          slidesPerView: Math.min(showCount, 2),
          slidesPerGroup: Math.min(showCount, 2)
        }
      }}
    >
      {Children.map(children, child => <div style={{ width: "100%" }}>{child}</div>)}
    </Swiper>
  )
}

export default MySwiper
