import React, { useState, useEffect } from 'react'
import { VictoryPie } from 'victory'
import { useTheme } from 'emotion-theming'
import { IMeerkatWebColors, IMeerkatWebTheme } from '../../../theme.style'

interface Props {
  width?: string
  height?: string
  value: number
  min: number
  max: number
  color?: keyof IMeerkatWebColors
  innerText: string
}

const PieGraph: React.FC<Props> = ({ width, height, value, min, max, color: useColor, innerText }) => {
  const theme = useTheme<IMeerkatWebTheme>()

  const [shownPercentage, setShownPercentage] = useState(0)

  const percentage = ((value - min) / (max - min)) * 100

  useEffect(() => {
    setTimeout(() => {
      setShownPercentage(percentage)
    }, 100)
  }, [percentage])

  const color = (useColor && theme.colors[useColor]) || theme.colors.primary

  return (
    <svg width={width || '100%'} height={height || 'auto'} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
      <circle
        cx={50}
        cy={50}
        r={41}
        stroke={color}
        style={{ strokeWidth: 18, fill: 'white', strokeDasharray: '0.5 1.5' }}
      />
      <VictoryPie
        standalone={false}
        animate={{
          duration: 2000,
        }}
        data={[shownPercentage, 100 - shownPercentage]}
        width={100}
        height={100}
        colorScale={[color, '#FFFFFF00']}
        innerRadius={32}
        radius={50}
        labels={() => ''}
      />
      <text x={50} y={50} textAnchor="middle" style={{ fontWeight: 700, fontSize: '14px', fill: theme.colors.dark }}>
        {Math.floor(value)}
      </text>
      <text x={50} y={60} textAnchor="middle" style={{ fontWeight: 400, fontSize: '8px', fill: theme.colors.dark }}>
        {innerText}
      </text>
    </svg>
  )
}

export default PieGraph
