import styled from '@emotion/styled'
import { device } from '../../../theme.style'

const TabsContainer = styled.div`
  .MuiTab-root {
    font-family: ${props => props.theme.fontFamily};
    font-weight: 900;
    font-size: 20px;

    ${device.desktopS`
      font-size: 18px;
    `}

    ${device.laptopL`
      font-size: 16px;
    `}

    ${device.laptop`
      font-size: 14px;
    `}

    ${device.tablet`
      font-size: 12px;
    `}

    color: ${props => props.theme.colors.dark};
    opacity: 0.3;
    text-transform: none;
  }

  .MuiTab-wrapper {
  }

  .Mui-selected {
    color: ${props => props.theme.colors.primary};
    opacity: 1;
  }

  .MuiTabs-indicator {
    background-color: ${props => props.theme.colors.primary}
  }
`

const TabContentsContainer = styled.div`
  padding: 14px;
  padding-top: 28px;
  padding-left: 7px;
`

export {
  TabsContainer,
  TabContentsContainer
}