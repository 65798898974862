import { useTranslation } from 'react-i18next'

function useMyTranslation(...args) {
  const result = useTranslation(...args)

  return {
    ...result,
    t: (...tArgs) => result.ready ? result.t(...tArgs) : "" 
  }
}

export default useMyTranslation