import React from 'react'
import { CardContainer } from './Card.style'

function Card({ children, className, onClick }) {
  return (
    <CardContainer className={className} onClick={onClick}>
      {children}
    </CardContainer>
  )
}

export default Card