import styled from '@emotion/styled';
import Text from '../Text';

const StepperContainer = styled.div`
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: row;

    box-shadow: 5px 22px 35px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
`;

const StepperContainerMobile = styled.div`
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: row;
`;

const StepContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 35px 10px 36px 10px;
`;

const StepContainerMobile = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    //maintain aspect ratio of box
    padding-top: 60px;
    position: relative;
`;


const StepHeaderText = styled(Text)`
    letter-spacing: 2px;
    text-transform: uppercase;
`;
const StepHeaderTextMobile = styled(Text)`
    letter-spacing: 2px;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export {
    StepperContainer,
    StepperContainerMobile,
    StepContainer,
    StepContainerMobile,
    StepHeaderText,
    StepHeaderTextMobile
}