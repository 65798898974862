import React from 'react'
import { Response } from './ChatFlow.style'
import { printCurrency, printDate } from '../../../lib/utils'

const displayForType = (type, value, props) => {
  console.log(type, value)

  if (type === "currency") {
    return printCurrency(value)
  }
  if (type === "date") {
    return printDate(value)
  }
  if (type === "select" || type === "options" || type === "radiogroup") {
    console.log(props.field.options)
    if (props.field.options[0] instanceof Object) {
      let result = value
      props.field.options.forEach(option => {
        if (option.value === value || option.value.toString() === value.toString()) { result =  option.label }
      })
      return result
    }
    return value
  }
  if (type === "yesno") {
    if (value === "Y") return "Yes"
    if (value === "N") return "No"
  }
  if (type === "secret") {
    return "*****" 
  }
  return value
}

const renderFieldDisplay = (type) => {
  const component = ({ input, ...props }) => {
    // console.log('Render Display type',type)
    // console.log('Render Display props',props)
    // console.log('Render Display input',input)
    return (
    <div>
      {displayForType(type, props.value || input && input.value || "", props)}
    </div>
  )}

  return component
}

export default renderFieldDisplay