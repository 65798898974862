import React, { useState, useEffect } from 'react'
import { VictoryPie } from 'victory'
import { useTheme } from 'emotion-theming'
import { getFillColor } from '../../savings/ProgressBar/ProgressBar.style'

// PercentagePie ...

function PercentagePie({ width, height, percentage, innerText }) {
  const theme = useTheme()

  const [shownPercentage, setShownPercentage] = useState(0)
  const [animating, setAnimating] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShownPercentage(percentage)
      setAnimating(true)
      setTimeout(() => setAnimating(false), 350)
    }, 100)
  }, [percentage])

  const color = getFillColor({ theme, percentage }).substring(0, 7)

  return <svg width={width} height={height}>
    {!animating && <VictoryPie
      standalone={false}
      data={[Math.min(5, shownPercentage / 2), 100 - Math.min(5, shownPercentage / 2)]}
      width={width}
      height={height}
      colorScale={[color, theme.colors.grey]}
      innerRadius={80}
      labels={() => ""}
    />}
     <VictoryPie
      standalone={false}
      animate={{
        duration: 2000
      }}
      data={[shownPercentage, 100 - shownPercentage]}
      width={width}
      height={height}
      colorScale={[color, theme.colors.grey]}
      innerRadius={80}
      labels={() => ""}
      cornerRadius={20}
    />
    <text x={width / 2} y={(height / 2) + 5} textAnchor="middle" style={{ fontWeight: 900, fontSize: "40px", fill: theme.colors.primary }}>{Math.round(percentage,0)}%</text>
    <text x={width / 2} y={(height / 2) + 30} textAnchor="middle" style={{ fontWeight: 400, fontSize: "14px", fill: theme.colors.dark }}>{innerText}</text>
  </svg>
}

export default PercentagePie
