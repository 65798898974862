/*
ChatFlow takes in a JSON chat definition and executes the chat flow with ChatCards
It also allows form submission to run a mutation
*/

// Disable Previous Step

import React, { useState, Fragment, useContext, useEffect } from 'react'
import ChatCard from '../../generic/ChatCard'
import { renderInput, renderLabel } from '../../generic/form'
import renderFieldDisplay from './renderFieldDisplay'
import { GraphQLFormProvider, FormProvider } from 'react-form-helper';
import ActivityIndicator from '../ActivityIndicator';
import { ChatContext } from '../../../context'

const resolveNextSteps = (nextSteps, chatState) => {
  if (nextSteps instanceof Function) {
    return nextSteps(chatState)
  } else {
    if (nextSteps instanceof Array) {
      return nextSteps
    }
    return [nextSteps]
  }
}
// TODO: Wrap in ChatCards ?
function Layout({ formName, apolloClient, changeFieldValue, steps, onlyShowCurrent, initialValues, submit, renderRequestContainer, renderResponseContainer }) {
  const { chatState, updateChatState, formSubmitDone, setFormSubmitDone } = useContext(ChatContext)
  let nextSteps = steps[0].nextSteps || []
  const [drawSteps, setDrawSteps] = useState([steps[0].name])
  const [currentSteps, setCurrentSteps] = useState([steps[0].name])
  const [previousSteps, setPreviousSteps] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [loadingCards, setLoadingCards] = useState({})
  const stepsByName = {}

  useEffect(() => {
    setChatState(initialValues)
  }, [])

  let nextChatState = { ...chatState }

  const setChatState = (values) => {
    if (values) {
      nextChatState = updateChatState(currentSteps, values)
    }
  }

  const updateChatFieldValue = (field, value) => {
    setChatState({ [field]: value })
    changeFieldValue(field, value)
  }

  const setLoading = (stepName, loading) => {
    setLoadingCards({ ...loadingCards, [stepName]: loading })
  }

  steps.forEach(step => {
    stepsByName[step.name] = step
  })

  const setNextSteps = (next) => {
    nextSteps = next
  }

  const gotoNext = (whichSteps) => {
    let addSteps = []
    const checkSteps = resolveNextSteps(whichSteps || nextSteps || [], nextChatState)
    let stepsSkipped = false

    checkSteps.forEach(stepName => {
      const step = stepsByName[stepName]
      if (step.skipStep && step.skipStep(nextChatState)) {
        stepsSkipped = true
        if (!step.handleNextIfSkipStep) {
          step.nextSteps && resolveNextSteps(step.nextSteps, nextChatState).forEach(s => addSteps.push(s))
        } else {
          step.handleNext({ apolloClient, changeFieldValue: updateChatFieldValue, chatState: nextChatState, setChatState, setNextSteps, setSubmitting, gotoNext: (s) => gotoNext(s || step.nextSteps) })
        }
      } else {
        addSteps.push(stepName)
      }
    })

    if (stepsSkipped && addSteps.length > 0) {
      gotoNext(addSteps)
    } else {
      addSteps.forEach(stepName => {
        const step = stepsByName[stepName]
        if (step.beforeStep) {
          setLoading(stepName, true)
          const stepResult = step.beforeStep({ stepName, apolloClient, setChatState, changeFieldValue: updateChatFieldValue, chatState: nextChatState })
          if (stepResult instanceof Promise) {
            stepResult.then(() => setLoading(stepName, false))
          } else {
            setLoading(stepName, false)
          }
        }
      })
      setPreviousSteps(prevSteps => {
        return [...prevSteps, ...addSteps.filter(stepName => !stepsByName[stepName].excludeFromBack)]
      })
      if (onlyShowCurrent) {
        setDrawSteps([...addSteps])
      } else {
        setDrawSteps([...drawSteps, ...addSteps])
      }
      setCurrentSteps(addSteps)
    }
  }

  const renderSteps = drawSteps.map((s, index) => {
    const step = stepsByName[s]

    if (loadingCards[s]) {
      if (step && step.noCard) {
        return <center><ActivityIndicator large="true"/></center>
      } else {
        return <ChatCard formName={formName} noNext><center><ActivityIndicator large="true"/></center></ChatCard>
      }
    }

    const chatCardProps = {
      text: step.text instanceof Function ? step.text(nextChatState) : step.text,
      fieldNames: step.fieldNames instanceof Function ? step.fieldNames(nextChatState) : step.fieldNames,
      requiredFields: step.requiredFields instanceof Function ? step.requiredFields(nextChatState) : step.requiredFields,
      fieldEvents: step.fieldEvents instanceof Function ? step.fieldEvents(nextChatState) : step.fieldEvents,
      nextOnValue: step.nextOnValue,
      noNext: step.noNext || !currentSteps.includes(step.name),
      goBack: () => {
        const previousStep = previousSteps[previousSteps.length - 2]
        setPreviousSteps(prevSteps => prevSteps.slice(0, -2))
        gotoNext(previousStep)
      },
      showBack: step.showBack,
      isActive: !!(currentSteps.includes(step.name) || (step.keepActive && step.keepActive(chatState))),
      focus: index === drawSteps.length - 1,
      labels: step.labels,
      submitting,
      setSubmitting,
      nextButtons: step.nextButtons instanceof Function ? step.nextButtons(chatState) : step.nextButtons,
      nextButtonsInColumn: step.nextButtonsInColumn,
      nextTitle: step.nextTitle instanceof Function ? step.nextTitle(nextChatState) : step.nextTitle,
      nextAfterDelay: step.nextAfterDelay,
      submitBeforeStep: step.submitBeforeStep,
      formSubmitDone,
      setFormSubmitDone,
      cardContentStyle: step.cardContentStyle,
      renderRequestContainer, 
      renderResponseContainer,
      submit,
      handleNext: (values, nextStepsOveride) => {
        if (step.handleNext) {
          setChatState(values)
          step.handleNext({ apolloClient, changeFieldValue: updateChatFieldValue, values, chatState: nextChatState, setChatState, setNextSteps, setSubmitting, gotoNext: (s) => gotoNext(s || nextStepsOveride || step.nextSteps) });
        } else {
          setChatState(values);
          gotoNext(nextStepsOveride || step.nextSteps);
        }
      }
    }
    if (step.component) {
      const Contents = step.component
      chatCardProps.children = <Contents chatState={nextChatState} apolloClient={apolloClient} gotoNext={(s) => {
        if (step.handleNext) {
          step.handleNext({ apolloClient, submit, changeFieldValue: updateChatFieldValue, chatState: nextChatState, setChatState, setNextSteps, setSubmitting, gotoNext: (s2) => gotoNext(s2 || s || step.nextSteps) });
        } else {
          gotoNext(s || step.nextSteps);
        }}
      } setNextSteps={setNextSteps} setChatState={setChatState} submitting={submitting} setSubmitting={setSubmitting} step={step} changeFieldValue={updateChatFieldValue} />
    }

    if (step.noCard) {
      return chatCardProps.children
    }

    return <ChatCard formName={formName} key={step.name} {...chatCardProps} />
  })

  return <Fragment>{renderSteps}</Fragment>
}

function ChatFlow({ id, name, mode, query, addMutation, updateMutation, steps, fields, initialValuesFromData, afterSubmit, onlyShowCurrent, onSubmit, initialValues, renderRequestContainer, renderResponseContainer, startWithCleanState = false, refetchQueries, mapInputVariables, keepInitialValues }) {
  const { chatState, formSubmitDone, setFormSubmitDone, clearChatState } = useContext(ChatContext)
  const [formInitialValues, setFormInitialValues] = useState()

  useEffect(() => {
    if (startWithCleanState) {
      clearChatState()
    }
    if (initialValues instanceof Function) {
      setFormInitialValues(initialValues(chatState) || {})
    } else {
      setFormInitialValues(initialValues || {})
    }
  }, [])

  if (!formInitialValues) {
    return <div />
  }

  if (query || addMutation || updateMutation) {
    return (
      <GraphQLFormProvider
        id={id}
        name={name}
        mode={mode || "add"}
        query={query}
        fields={fields}
        addMutation={addMutation}
        updateMutation={updateMutation}
        afterSubmit={(...args) => { afterSubmit && afterSubmit(...args); setFormSubmitDone(true); }}
        renderInput={renderInput}
        renderDisplay={renderFieldDisplay}
        initialValuesFromData={initialValuesFromData}
        initialValues={formInitialValues}
        InputFormLayout={(props) => <Layout formName={name} steps={steps} onlyShowCurrent={onlyShowCurrent} initialValues={formInitialValues} {...props} 
        renderRequestContainer={renderRequestContainer} renderResponseContainer={renderResponseContainer} />}
        refetchQueries={refetchQueries}
        mapInputVariables={mapInputVariables}
        keepInitialValues={keepInitialValues}
      />
    )
  } else {
    return (
      <FormProvider
        name={name}
        mode="add"
        fields={fields}
        afterSubmit={(...args) => { afterSubmit && afterSubmit(...args); setFormSubmitDone(true); }}
        renderInput={renderInput}
        renderDisplay={renderFieldDisplay}
        renderLabel={renderLabel}
        initialValues={formInitialValues}
        InputFormLayout={(props) => <Layout formName={name} steps={steps} onlyShowCurrent={onlyShowCurrent} initialValues={formInitialValues} {...props}
        renderRequestContainer={renderRequestContainer} renderResponseContainer={renderResponseContainer} />}
        refetchQueries={refetchQueries}
      />
    )
  }
}

export default ChatFlow