import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import ActivityIndicator from '../ActivityIndicator'

const COMPLETE_AUTH = gql`
  mutation CompleteAuth($code: String) {
    completeAuth(code: $code)
  }
`

function OAuthResponse() {
  const [completeAuth] = useMutation(COMPLETE_AUTH)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    console.log(window.location)
    const temp = new URLSearchParams(window.location.search)
    console.log(temp)

    completeAuth({ variables: { code: temp.get('code') } }).then(data => {
      console.log('Returned', data)
      setLoading(false)
    })
  }, [])

  return <div>
    {loading ? <ActivityIndicator large /> : "Authentication Completed"}
  </div>
}

export default OAuthResponse