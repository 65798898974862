import React, { Children, useState } from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views'
import { TabsContainer, TabContentsContainer } from './Tabs.style'

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function MyTabs({ children }) {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const handleTabChange = (e, v) => {
    setSelectedIndex(v)
  }

  const handleTabChangeSwipe = (v) => {
    setSelectedIndex(v)
  }

  return <TabsContainer>
    <Tabs
      value={selectedIndex}
      onChange={handleTabChange}
      aria-label="full width tabs example"
    >
      {Children.map(children, (item, index) => <Tab label={item.props.tabTitle || `Tab ${index + 1}`} {...a11yProps(index)} />)}
    </Tabs>
    <TabContentsContainer>
      <SwipeableViews
        axis="x"
        index={selectedIndex}
        onChangeIndex={handleTabChangeSwipe}
      >
        {Children.map(children, (item, index) => React.cloneElement(item, { index, dir: "x" }))}
      </SwipeableViews>
    </TabContentsContainer>
  </TabsContainer>
}

export default MyTabs
