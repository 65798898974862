import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import ActivityIndicator from '../ActivityIndicator'
import { Col } from '../grid'

const GET_OAUTH_URL = gql`{
  url: getAuthUrl
}`

function OAuthRequest() {
  const { data, loading, error }= useQuery(GET_OAUTH_URL)

  if (loading) { return <ActivityIndicator /> }
  if (error) { return "An error has occurred" }

  window.location = data.url

  return (
    <div>
      <Col centerHorizontal>
        We are going to redirect you to our partner to complete secure authentication
      </Col>
      <Col centerHorizontal pad>
        <ActivityIndicator large />
      </Col>
    </div>
  )
}

export default OAuthRequest