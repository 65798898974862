import styled from '@emotion/styled'

const ProgressBarContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 226px;
  #height: 50px;
  height: 55px;
`


const colorChanges = [30, 70]

const getFillColor = ({ percentage, theme }) => {
  if (percentage < colorChanges[0]) {
    return theme.colors.red
  }
  if (percentage < colorChanges[1]) {
    return theme.colors.secondary
  }
  return `${theme.colors.primary}99`
}

const ProgressBarDone = styled.div`
  background-color: ${props => (props.color && props.theme.colors[props.color]) || getFillColor(props)};
  border-radius: 4px;
  height: 8px;
  width: ${({ percentage }) => ((percentage || 0))}%;
`

const ProgressBarFull = styled.div`
  position: absolute;
  #top: 32px;
  top: 12px;
  left: 0px;
  #background-color: rgba(34, 148, 144, 0.29);
  #background-color: rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
  border-radius: 4px;
  height: 8px;
  width: 100%;
  box-shadow: 0px 0px 10px rgba(64, 67, 87, 0.12);
`

const ProgressBarTextContainer = styled.div`
  position: absolute;
  left: ${({ percentage }) => (((percentage || 0) / 100) * 226) - 30}px;
  top: 7px;
  width: 60px;
  text-align: center;
`

const ProgressTextContainer =  styled.div`
  margin-top: 35px;
  width: 100%;
  display: flex;
  flex-direction: row;
  opacity: 0.8;
`

const ProgressPercent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
`

const ProgressRemaining = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
`

export {
  ProgressBarContainer,
  ProgressBarTextContainer,
  ProgressBarDone,
  ProgressBarFull,
  ProgressTextContainer,
  ProgressPercent,
  ProgressRemaining,
  getFillColor
}