import React, { useEffect, useState } from 'react'
import { ModalBackdrop, ModalContainer, ModalContentsContainer, ModalTitleBarContainer, ModalTitleContainer, CloseModalContainer, ModalContents } from './SlideUpModal.style'
import PropTypes from 'prop-types'
import { useIsMount } from '../../../hooks'
import Text from '../Text'

function SlideUpModal({ children, open, setOpen, className, title }) {
  const [animating, setAnimating] = useState(false)
  const [isOpen, setIsOpen] = useState(open)
  const isMount = useIsMount()

  useEffect(() => {
    if (!isMount) {
      setAnimating(true)
      setTimeout(() => {
        setIsOpen(open)
        setAnimating(false)
      }, 490)
    }
  }, [open])

  return <ModalBackdrop open={isOpen} animating={animating} className={className}>
    <ModalContainer>
      <ModalTitleBarContainer>
        <ModalTitleContainer>
          <Text xlarge>{title}</Text>
        </ModalTitleContainer>
        <CloseModalContainer>
          <Text primary onClick={() => setOpen(false)}>Back</Text>
        </CloseModalContainer>
      </ModalTitleBarContainer>
      <ModalContentsContainer>
        <ModalContents>
          {children}
        </ModalContents>
      </ModalContentsContainer>
    </ModalContainer>
  </ModalBackdrop>
}

SlideUpModal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  title: PropTypes.string
}

export default SlideUpModal