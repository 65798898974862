import { useState, useEffect  } from 'react'

function useStateThen(initial) {
    const [value, setValue] = useState(initial)
    const [callback, setCallback] = useState()

    useEffect(() => {
        console.log("Running callback")
        callback && callback()
    }, [value])

    const setValueWithCallback = (newValue, runCallback) => {
        setCallback(runCallback)
        setValue(newValue)
    }
    
    return [value, setValueWithCallback]
}

export default useStateThen